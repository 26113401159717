<template>
  <div>
      <v-app>
        <v-dialog v-model="addToCartErrorFlag" persistent max-width="500">
                <v-card>
                    <v-card-title>
                            <p class="ma-0 body-heading font-weight-semi-bold">{{ $t("Warning_Message.Error")}}</p>
                    </v-card-title>

                    <v-divider></v-divider>

                    <div class="pa-4">
                        <!-- Error Message -->
                        <p class="ma-0 body-text font-weight-regular" align="left">{{ $t("Warning_Message.Something_went_wrong")}}</p>
                    </div>
                    <v-card-actions class="ma-0 pa-0">
                        <div class="px-4 pb-4 full-width-button d-flex justify-center align-center">
                            <button class="mt-2 px-4 py-2 rounded light-blue-background edit-address-action-button" @click="addToCartErrorPopupClose()"><span class="px-2 py-2 body-text white-color-text font-weight-semi-bold">{{ $t("Customer.VideoCallTestPage.OK")}}</span></button>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="addToCartErrorPopupFlag" persistent max-width="500">
                <v-card>
                    <v-card-title>
                            <p class="ma-0 body-heading font-weight-semi-bold">{{ $t("Warning_Message.Add_to_cart_failed")}}</p>
                    </v-card-title>

                    <v-divider></v-divider>

                    <div class="pa-4">
                        <!-- Error Message -->
                        <p class="ma-0 body-text font-weight-regular" align="left">{{ $t("Warning_Message.Something_went_wrong_please_try_again")}}</p>
                    </div>
                    <v-card-actions class="ma-0 pa-0">
                        <div class="px-4 pb-4 full-width-button d-flex justify-center align-center">
                            <button class="mt-2 px-4 py-2 rounded light-blue-background edit-address-action-button" @click="addToCartErrorPopupClose()"><span class="px-2 py-2 body-text white-color-text font-weight-semi-bold">{{ $t("Customer.VideoCallTestPage.OK")}}</span></button>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-dialog>
          <div class="d-flex-column justify-center align-center">
              <!-- Header -->
              <div class="web-header-segment">
                  <common-home-header 
                      :key="commonHomeComponentKey" 
                      @getCartData="getCartDataFromCommonHomeHeader"
                      :displayLoginPopup="guestUserAddToCart"
                      @loginPopupClosed="checkIfLoginPopupClosed"
                      :callpermissionflag="callpermissionflag"
                      :errorDialogue="error_enable"
                      :authDialogue="auth_error_enable"
                      :cartQuantity="cartQuantity"
                      >
                  </common-home-header>
              </div>

              <v-divider></v-divider>

              <!-- Navigation Tabs on Web -->
              <div class="navigation-tabs-segment">
          <header-tabs-component
            :tab_id="1"
            :is_user_logged_in="isUserLoggedIn"
          ></header-tabs-component>
              </div>

              <!-- Header on Mobile -->
              <div class="mobile-header-segment">
                  <div class="pa-4 d-flex justify-space-between">
                      <div class="d-flex align-center">
                          <img src="https://s3iconimages.mymedicine.com.mm/back_button.svg" alt="back-button" @click="redirectBack()">
                          <p class="ma-0 pl-2 body-heading font-weight-medium">{{ $t("Customer.productComponent.Product_Details")}}</p>
                      </div>

                      <div class="d-flex align-center cart-icon" @click="redirectToCartPage()">
                          <img class="icon-class" src="https://s3iconimages.mymedicine.com.mm/header_cart_blue_bg_icon.svg" alt="cart-icon">
                          <div v-if="cartQuantity > 0" class="d-flex justify-center align-center white-background products-length-container">
                              <div class="d-flex justify-center align-center light-red-background products-length">
                                  <p class="ma-0 white-color-text body-small-font font-weight-semi-bold">{{ cartQuantity }}</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div class="display-mobile">
                  <v-divider></v-divider>
              </div>

              <!-- Loader Web -->
              <div v-if="skeletonLoaderFlag" class="loader-web grey-background">
                  <div class="container py-6">
                      <div class="pa-8 rounded-lg white-background">
                          <div class="row">
                              <div class="mr-2 col-4">
                                  <div class="">
                                      <v-skeleton-loader elevation="0" type="card" class=""></v-skeleton-loader>
                                  </div>
                              </div>

                              <div class="ml-2 col-7 pa-4">
                                  <v-skeleton-loader elevation="0" type="article, actions" class="py-2"></v-skeleton-loader>
                              </div>
                          </div>

                          <div class="pa-4">
                              <v-skeleton-loader elevation="0" type="heading" class="py-2"></v-skeleton-loader>
                              <v-skeleton-loader elevation="0" type="image" class="py-2"></v-skeleton-loader>
                          </div>

                          <div class="pa-4">
                              <v-skeleton-loader elevation="0" type="heading"></v-skeleton-loader>

                              <div class="d-flex">
                                  <v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
                                  <v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
                                  <v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>

                              </div>

                              <div class="d-flex">
                                  <v-skeleton-loader elevation="0" type="card" width="180" height="280" class="mx-2"></v-skeleton-loader>
                                  <v-skeleton-loader elevation="0" type="card" width="180" height="280" class="mx-2"></v-skeleton-loader>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <!-- Loader Mobile -->
              <div v-if="skeletonLoaderFlag" class="container loader-mobile">
                  <v-skeleton-loader elevation="0" type="image"></v-skeleton-loader>

                  <v-skeleton-loader elevation="0" type="article, actions"></v-skeleton-loader>

                  <v-divider></v-divider>

                  <div class="pa-4">
                      <v-skeleton-loader elevation="0" type="heading" class="py-2"></v-skeleton-loader>
                      <v-skeleton-loader elevation="0" type="image" class="py-2"></v-skeleton-loader>
                  </div>

                  <v-divider></v-divider>


                  <div class="pa-4">
                      <v-skeleton-loader elevation="0" type="heading"></v-skeleton-loader>

                      <div class="d-flex">
                          <v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
                          <v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
                      </div>

                      <div class="d-flex">
                          <v-skeleton-loader elevation="0" type="card" width="180" height="280" class="mx-2"></v-skeleton-loader>
                          <v-skeleton-loader elevation="0" type="card" width="180" height="280" class="mx-2"></v-skeleton-loader>
                      </div>
                  </div>
              </div>

              <!-- Container -->
              <v-overlay v-model="selectQuantityPopupFlag"></v-overlay>
              <div  class="product-container">
                  <div class="container">
                      <!-- Segment 1 -->
                      <!-- View Cart Bar -->
                      <div v-if="!skeletonLoaderFlag">
                          <div v-if="showAddedToCartDiv" class="pb-4 display-web">
                              <div class="view-cart-bar white">
                                  <div class="px-4 py-2 mb-4 mb-lg-8 d-flex justify-space-between align-center">
                                      <div class="d-flex">
                                          <p class="ma-0 pr-4 body-heading font-weight-medium dark-green-color-text">{{ $t("Customer.productComponent.Added_to_cart")}}</p>
                                      </div>

                                      <div class="d-flex justify-center align-center" align="right">
                                          <button class="px-4 py-2 rounded body-heading font-weight-semi-bold white-color-text light-blue-background" @click="redirectToCartPage">{{ $t("Customer.productComponent.View_Cart")}}</button>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <!-- Segment 2 -->
                          <!-- Product Main Info -->
                          <div class="white segment-2">
                              <!-- Breadcrumbs -->
                              <div class="breadcrumbs-segment">
                                  <v-breadcrumbs :items="breadcrumbs" class="body-text font-weight-medium">
                                      <template v-slot:item="{ item }">
                                          <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                                              <span v-if="item != breadcrumbs[breadcrumbs.length-1]" class="body-text font-weight-medium BDBDBD-grey-color-text dark-grey-text-on-hover">{{ item.text }}</span>
                                              <span v-else class="body-text font-weight-medium dark-grey-color-text">{{ item.text }}</span>
                                          </v-breadcrumbs-item>
                                      </template>
                                  </v-breadcrumbs>
                              </div>
                              
                              <div class="pa-0 mx-0 product-info">
                                  <!-- Product Images -->
                                  <!-- Web -->
                                  <div class="images-container">
                                        <div class="display-web">
                                            <div v-if="productImages && productImages.length > 0" class="d-flex justify-center align-center">
                                                <product-display-component :key="productImagesComponentKey" :prodImages="productImages"></product-display-component>
                                            </div>
                                            <div v-else-if="productDetails.product_icon_image">
                                                <product-display-component :key="productImagesComponentKey" :prodImages="[productDetails.product_icon_image]"></product-display-component>
                                            </div>
                                            <div v-else>
                                                <product-display-component :key="productImagesComponentKey" :prodImages="['https://s3iconimages.mymedicine.com.mm/product_fallback_image_large.svg']"></product-display-component>
                                            </div>
                                        </div>
                                  </div>

                                  <!-- Mobile -->
                                  <div class="display-mobile images-container">
                                      <div class="">
                                          <!-- Mobile -->
                                          <div v-if="productImages && productImages.length > 0" class="px-0 pt-0 pb-2">
                                              <v-carousel height="auto" cycle hide-delimiters :show-arrows="false" class="product-images-carousel" v-model="productIndex">
                                                  <v-carousel-item v-for="product, index in productImages" :key="index">
                                                      <img class="product-display-image-mobile" :src="product.URL ? product.URL : 'https://s3iconimages.mymedicine.com.mm/product_fallback_image_large.svg'" alt="product-image">
                                                  </v-carousel-item>
                                              </v-carousel>

                                              <div class="d-flex justify-center align-center">
                                                  <div v-for="i in productImages.length" :key="i">
                                                      <div class="px-1" v-if="(i-1) == productIndex">
                                                          <img src="https://s3iconimages.mymedicine.com.mm/active_carousel_item_icon.svg" alt="active-carousel-item">
                                                      </div>
                                                      <div v-else class="px-1">
                                                          <img src="https://s3iconimages.mymedicine.com.mm/inactive_carousel_item_icon.svg" alt="active-carousel-item">
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>

                                  <!-- Product Data -->
                                  <div id="product-data-segment" class="data-container">
                                      <div class="d-flex flex-column">
                                          <!-- <p class="ma-0 body-caps-text font-weight-medium light-grey-color-text text-uppercase" align="left">{{ productDetails.category }}</p> -->
                                          <p class="ma-0 pb-2 title-heading font-weight-semi-bold dark-grey-color-text" align="left">{{ productDetails.product_name }}</p>
                                          
                                          <div class="d-flex align-center py-2" v-if="productStockFlag != null">
                                              <div v-if="productStockFlag" class="d-flex align-center">
                                                  <img class="small-icon-class" src="https://s3iconimages.mymedicine.com.mm/instockIcon.svg" alt="in-stock-icon">
                                                  <p class="ma-0 pl-2 body-text font-weight-medium green-color-text">{{ $t("Customer.productComponent.In_Stock")}}</p>
                                              </div>
                                              <div v-else class="d-flex align-center">
                                                  <img class="small-icon-class" src="https://s3iconimages.mymedicine.com.mm/outOfStockIcon.svg" alt="out-of-stock-icon">
                                                  <p class="ma-0 pl-2 body-text font-weight-medium light-red-color-text">{{ $t("Customer.productComponent.Out_of_Stock")}}</p>
                                              </div>

                                              <div class="pl-4 d-flex align-center" v-if="productDetails.prescription_required">
                                                  <img class="small-icon-class" src="https://s3iconimages.mymedicine.com.mm/prescriptionSymbol.svg" alt="prescription-required">
                                                  <p class="ma-0 pl-2 body-text font-weight-medium d-inline light-blue-color-text">{{ $t("Customer.productComponent.Prescription_Required")}}</p>
                                              </div>
                                          </div>

                                          <div v-if="!productDetails.packaging_size && productDetails.packaging_size" class="py-2">
                                              <!-- <p class="ma-0 pb-1 body-heading font-weight-medium dark-grey-color-text" align="left">Product Code: {{ productDetails.product_code }}</p> -->
                                              <p class="ma-0 py-1 body-heading font-weight-medium dark-grey-color-text" align="left">{{ $t("Customer.productComponent.Packaging_Size")}}: {{ productDetails.packaging_size }}</p>
                                              <!-- <p class="ma-0 pt-1 body-heading font-weight-medium dark-grey-color-text" align="left">By {{ productDetails.manufacturer }}</p> -->
                                          </div>
                                          
                                          <!-- Ratings Bar -->
                                          <div v-show="false" class="py-1">

                                          </div>

                                          <!-- Variants -->
                                          <div style="width:300px;" v-if="productDetails.product_variants && productDetails.product_variants.length > 0" class="py-2 d-flex flex-column ">
                                              <!-- <p class="ma-0 pb-1 body-heading font-weight-semi-bold dark-grey-color-text" align="left">Selected Variants</p> -->
                                              
                                              <v-select  
                                                label="Select Variants"
                                                :items="productDetails.product_variants"
                                                item-value="id"
                                                item-text="display_name"
                                                @change="getNewVariant"
                                                v-model="selectedVariant"
                                                :loading="variantLoading"
                                                outlined
                                                :readonly="addToCartLoaderFlag"
                                              >
                                              </v-select>
                                              <!-- <div class="pt-1 d-flex flex-wrap">
                                                  <div v-for="variant, index in productDetails.variants" :key="index">
                                                      <button class="mr-2 px-2 py-1 rounded" :class="[selectedVariant != null && selectedVariant == index ? 'light-blue-background white-color-text' : 'light-blue-border light-blue-color-text']" @click="selectedVariant=index">
                                                          {{ variant }}
                                                      </button>
                                                  </div>
                                              </div> -->
                                          </div>

                                          <!-- Price -->
                                          <div class="py-2 d-flex flex-column" v-if="!productDetails.isPriceDataLoading">
                                            <div v-if="productDetails.hasDiscount">
                                                <p class="ma-0 body-heading font-weight-semi-bold light-blue-color-text" style="font-size: 10px;" align="left"><strike> {{ productDetails.list_price }} KS </strike></p>
                                                <p class="ma-0 body-heading font-weight-semi-bold light-blue-color-text" style="font-size: 14px;" align="left">{{ productDetails.discountedPrice }} KS </p>
                                                <p class="ma-0 body-text font-weight-medium light-grey-color-text" align="left">{{ $t("Customer.productComponent.Inclusive_of_all_taxes")}}</p>
                                            </div>
                                            <div v-else>
                                                <p class="ma-0 body-heading font-weight-semi-bold light-blue-color-text" align="left">{{ productDetails.list_price }} KS</p>
                                                <p class="ma-0 body-text font-weight-medium light-grey-color-text" align="left">{{ $t("Customer.productComponent.Inclusive_of_all_taxes")}}</p>
                                            </div>
                                          </div>
                                          <div class="py-2 d-flex flex-column" v-else>
                                            <v-progress-circular size="20" indeterminate color="#48ACEF"></v-progress-circular>
                                            <p class="ma-0 body-text font-weight-medium light-grey-color-text" align="left"> Getting the latest price</p>
                                          </div>
                                        <!-- Product Quantity -->
                                        <v-select class="selector-container border custom-select"
                                                v-model="SelectedQuantity"
                                                :items="states"
                                                :no-data-text="$t('Customer.productComponent.Out_of_Stock')"
                                                menu-props="auto"
                                                dense
                                                hide-details
                                                prefix="Qty: "
                                                outlined
                                                :readonly="addToCartLoaderFlag || variantLoading"
                                                label="Select Quantity"
                                            >
                                            </v-select>
                                        
                                        <div class="cart-button-container">
                                            <div class="my-2 d-flex align-center" v-if="quantityLimitReached">
                                              <button  disabled class="px-2 py-1 rounded BDBDBD-grey-background white-color-text action-button button-class">{{ $t("Customer.productComponent.Out_of_Stock")}}</button> 
                                          </div>
                                          <v-btn v-else-if="productStockFlag && productStockFlag != null"
                                            class="cart-container" color="#48ACEF" 
                                            :class="{'loading': addToCartLoaderFlag}"
                                            :disabled="addToCartLoaderFlag || variantLoading"
                                            elevation="0"
                                            :loading="addToCartLoaderFlag"
                                            @click="incrementQuantity()"><span class="white-color-text"> {{ $t("Customer.productComponent.Add_to_cart")}}</span></v-btn>
                                        </div>
                                        
                                          <!-- Delivery -->
                                          <div v-show="false">
                                              <div class="mt-2 mr-sm-6 pa-4 d-flex flex-column align-flex-start rounded-lg light-grey-border">
                                                  <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text" align="left">{{ $t("Customer.productComponent.Delivery")}}</p>

                                                  <div class="pt-2 d-flex align-center delivery-segment">
                                                      <p class="ma-0 body-text font-weight-medium pr-2" align="left">{{ $t("Customer.productComponent.Your_State")}}: </p>
                                                      <v-text-field solo flat placeholder="Enter State" hide-details="auto" class="text-field light-grey-border"
                                                          @click="" @keyup.enter="" @click:append=""
                                                      >
                                                          <template #append>
                                                              <button class="button-class light-blue-color-text">{{ $t("Customer.productComponent.Check")}}</button>
                                                          </template>
                                                      </v-text-field>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      
                          <div class="display-mobile">
                              <v-divider></v-divider>
                          </div>

                          <!-- Description Tabs & Ratings -->
                          <div v-if="(productDetails.description && (productDetails.description != '' || productDetails.description != 'NA')) || (productDetails.uses && (productDetails.uses != '' || productDetails.uses != 'NA')) || (productDetails.side_effects && (productDetails.side_effects != '' || productDetails.side_effects != 'NA')) || (productDetails.precautions && (productDetails.precautions != '' || productDetails.precautions != 'NA'))" class="description-ratings">
                              <!-- Segment 3 -->
                              <!-- Tabs -->
                              <div class="white description-tags segment-3">
                                  <v-tabs v-model="tabs" align-tabs="left">
                                      <v-tab v-if="productDetails.description && (productDetails.description != '' || productDetails.description != 'NA')">
                                          {{ $t("Customer.MyParticularSubscription.Description")}}
                                      </v-tab>
                                      
                                      <v-tab v-if="productDetails.uses && (productDetails.uses != '' || productDetails.uses != 'NA')">
                                          {{ $t("Customer.productComponent.Uses")}}
                                      </v-tab>

                                      <v-tab v-if="productDetails.side_effects && (productDetails.side_effects != '' || productDetails.side_effects != 'NA')">
                                          {{ $t("Customer.productComponent.Side_Effects")}}
                                      </v-tab>

                                      <v-tab v-if="productDetails.precautions && (productDetails.precautions != '' || productDetails.precautions != 'NA')">
                                          {{ $t("Customer.productComponent.Precautions_&_Warnings")}}
                                      </v-tab>
                                  </v-tabs>

                                  <v-divider class="mb-1"></v-divider>

                                  <v-tabs-items class="ma-4 description-tag-info" v-model="tabs">
                                      <v-tab-item v-if="productDetails.description && (productDetails.description != '' || productDetails.description != 'NA')">
                                          <p class="ma-0 pa-2 body-heading font-weight-medium" align="left">{{ productDetails.description }}</p>
                                      </v-tab-item>

                                      <v-tab-item v-if="productDetails.uses && (productDetails.uses != '' || productDetails.uses != 'NA')">
                                          <p class="ma-0 pa-2 body-heading font-weight-medium" align="left">{{ productDetails.uses }}</p>
                                      </v-tab-item>

                                      <v-tab-item v-if="productDetails.side_effects && (productDetails.side_effects != '' || productDetails.side_effects != 'NA')">
                                          <p class="ma-0 pa-2 body-heading font-weight-medium" align="left">{{ productDetails.side_effects }}</p>
                                      </v-tab-item>

                                      <v-tab-item v-if="productDetails.precautions && (productDetails.precautions != '' || productDetails.precautions != 'NA')">
                                          <p class="ma-0 pa-2 body-heading font-weight-medium" align="left">{{ productDetails.precautions }}</p>
                                      </v-tab-item>
                                  </v-tabs-items>
                              </div>

                              <!-- Segment 4 -->
                              <!-- Ratings -->
                              <div v-show="false" class="ratings segment-4">

                              </div>
                          </div>

                          <div class="display-mobile">
                              <v-divider></v-divider>
                          </div>
                      </div>
                      <!-- More Products Segment 1 -->
                      <div class="products-component-2">
                          <div class="pa-4" v-if="bestSalersLoader">
                              <v-skeleton-loader elevation="0" type="heading"></v-skeleton-loader>

                              <div class="d-flex">
                                  <v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
                                  <v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
                              </div>

                              <div class="d-flex">
                                  <v-skeleton-loader elevation="0" type="card" width="180" height="280" class="mx-2"></v-skeleton-loader>
                                  <v-skeleton-loader elevation="0" type="card" width="180" height="280" class="mx-2"></v-skeleton-loader>
                              </div>
                          </div>
                          <promotion-products-component  v-else class="segment-5"
                              :data="productsList1"
                              listings_type="LISTINGS-ONE-ROW"
                          ></promotion-products-component>
                      </div>
                  </div>
              </div>
              
            <!-- Bottom Sticky bar -->
            <div v-if="!skeletonLoaderFlag"
                class="bottom-navbar-segment mobile-bottom-nav-bar"
                :class="[selectQuantityPopupFlag ? '' : 'bottom-sticky-shadow']"
            >
                <div v-if="selectQuantityPopupFlag">
                    <v-divider></v-divider>
                </div>
                <div v-if="selectQuantityPopupFlag" class="">
                    <div class="bottom-sticky-shadow white-background select-quantity-popup">
                        <div class="pa-4">
                            <!-- variants -->
                            <div>
                                <div class="" align="right">
                                    <v-btn elevation="0" solo color="white" class="pa-0 rounded-lg close-button" @change="SelectedQuantity=1;" @click="selectQuantityPopupFlag=false;" >
                                        <img class="icon-class" src="https://s3iconimages.mymedicine.com.mm/closeBtn.svg" alt="close-icon">
                                    </v-btn>
                                </div>
                                
                                <p class="ma-0 mb-4 font-16px font-weight-bold" align="left">{{ $t('Customer.productComponent.Select_Variant') }}</p>

                                <v-select
                                    :items="productDetails.product_variants"
                                    item-value="id"
                                    item-text="display_name"
                                    @change="getNewVariant"
                                    v-model="selectedVariant"
                                    :loading="variantLoading"
                                    outlined
                                    :readonly="addToCartLoaderFlag"
                                    label="Select Variant"
                                >
                                </v-select>
                            </div>

                            <!-- Quantity -->
                            <div class="">
                                <p class="ma-0 mb-4 font-16px font-weight-bold" align="left">{{ $t('Customer.productComponent.Select_Quantity') }}</p>
                                <v-select
                                    :items="states"
                                    v-model="SelectedQuantity"
                                    :no-data-text="$t('Customer.productComponent.Out_of_Stock')"
                                    outlined
                                    :readonly="addToCartLoaderFlag || variantLoading"
                                    label="Select Quantity"
                                >
                                </v-select>
                            </div>
                        </div>

                        <div class="bottom-navbar-segment mobile-bottom-nav-bar">
                            <v-divider></v-divider>

                            <div :class="[selectQuantityPopupFlag ? 'white-background' : '']" class="pa-4 white-background d-flex justify-space-between align-center">
                                <!-- Price -->
                                <p class="ma-0 mr-2 font-16px light-blue-color-text font-weight-bold one-third-width">{{ totalPrice * SelectedQuantity }} KS</p>

                                <!-- Add to cart Button -->
                                <div class="ml-4 flex-grow-1 flex-shrink-0">
                                    <v-btn v-if="productStockFlag && productStockFlag != null" elevation="0" color="#48ACEF"
                                        class="full-width-button light-blue-background"
                                        :class="{'loading': addToCartLoaderFlag}"
                                        :disabled="addToCartLoaderFlag || variantLoading"
                                        :loading="addToCartLoaderFlag"
                                        @click="addToCartFromBottomSticky()"
                                    >
                                        <span class="white-color-text">{{ $t("Customer.productComponent.Add_to_cart")}}</span>
                                    </v-btn>

                                    <v-btn v-else disabled class="BDBDBD-grey-background full-width-button">
                                        <span class="white-color-text">{{ $t("Customer.productComponent.Out_of_Stock")}}</span>
                                        <!-- <button  class="px-2 py-1 rounded  white-color-text action-button button-class"></button> -->
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!selectQuantityPopupFlag" class="add-to-cart-segment bottom-sticky-shadow">
                    <div v-if="productAddedToCartIndication" class="dark-green-background rounded-t-lg">
                        <p class="ma-0 ml-0 mb-0 py-1 body-heading white-color-text font-weight-medium" align="center">{{ $t("Customer.productComponent.Added_to_cart") }}!</p>
                    </div>
                    <div v-if="productErrorIndication" class="red-background rounded-t-lg">
                        <p class="ma-0 ml-0 mb-0 py-1 body-heading white-color-text font-weight-medium" align="center">{{ $t("Customer.productComponent.Something_went_wrong_please_try_again") }}!</p>
                    </div>
                    <v-divider></v-divider>

                    <div :class="[selectQuantityPopupFlag ? 'white-background' : '']" class="pa-4 white-background">
                        <div class="d-flex justify-space-between align-center">
                            <!-- Price -->
                            <p class="ma-0 mr-2 font-16px light-blue-color-text font-weight-bold one-third-width">{{ totalPrice * SelectedQuantity }} KS</p>

                            <!-- Add to cart Button -->
                            <div class="ml-4 flex-grow-1 flex-shrink-0">
                                <v-btn v-if="productStockFlag && productStockFlag != null" elevation="0"
                                    class="full-width-button light-blue-background white-color-text"
                                    :disabled="addToCartLoaderFlag || variantLoading"
                                    :loading="addToCartLoaderFlag"
                                    @click="addToCartFromBottomSticky()"
                                >
                                    <span>{{ $t("Customer.productComponent.Add_to_cart")}}</span>
                                </v-btn>
                                <v-btn v-else disabled class="BDBDBD-grey-background full-width-button">
                                    <span class="white-color-text">{{ $t("Customer.productComponent.Out_of_Stock")}}</span>
                                    <!-- <button  class="px-2 py-1 rounded  white-color-text action-button button-class"></button> -->
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

              <!-- Footer for Web -->
              <div class="display-web">
                  <common-home-footer class="pa-4"></common-home-footer>
              </div>
          </div>
      </v-app>
  </div>
</template>

<script>
import { axios_auth_instance_epharmacy_customer } from '../../../utils/axios_utils.js';
import { checkIfImageExists, handleError } from '../../../utils/utils.js';
import axios from "axios";
export default {
    name: 'ProductDetailsPage',
    components: {
        'common-home-header': () => import('../../../components/navigationComponents/commonHomeHeader.vue'),
        'header-tabs-component': () => import('../../../components/navigationComponents/headerTabsComponent.vue'),
        'promotion-products-component': () => import('../../../components/ePharmacyComponents/multipleStyleListingsComponent.vue'),
        'product-display-component': () => import('../../../components/ePharmacyComponents/productDisplayComponent.vue'),
        'bottom-navigation-bar': () => import('../../../components/navigationComponents/bottomNavigationBar.vue'),
        'common-home-footer': () => import('../../../components/navigationComponents/commonHomeFooter.vue'),
    },
    data() {
        return {
            variantLoading: false,
            bestSalersLoader: true,
            token: null,
            screenWidth: window.innerWidth,
            skeletonLoaderFlag: true,
            breadcrumbs: [
                {
                    text: 'HOME',
                    disabled: false,
                    href: window.location.origin + '/'
                },
                {
                    text: 'PHARMACY',
                    disabled: false,
                    href: window.location.origin + '/customer/epharmacyHome'
                },
                {
                    text: 'CATEGORIES',
                    disabled: false,
                    href: window.location.origin + '/customer/allcategories'
                },
                {
                    text: 'PRODUCT',
                    disabled: true,
                    href: window.location.origin + '/customer/productdetails/' + this.$route.params.product_id
                },
            ],
            commonHomeComponentKey: 0,
            isUserLoggedIn: false,
            productIndex: 0,
            product_id: null,
            headerTabs: null,
            productStockFlag: null,
            availableStock: null,
            quantityLimitReached: false,
            addToCartLoaderFlag: false,
            addToCartErrorFlag: false,
            addToCartErrorPopupFlag: false,
            removeFromCartFlag: false,
            selectedVariant: null,
            productDetails: {
                images: [],
                category: '',
                name: '',
                name_mm: '',
                product_code: '',
                packaging_size: '',
                manufacturer: '',
                product_variants: [],
                variants: [],
                price: '',
                quantity_added: 0,
                max_quantity_permitted: null,
                selling_unit: '',
                description_tabs: [
                    'Description',
                    'Uses',
                    'Side Effects',
                    'Prescription and Warnings'
                ]
            },
            productImages: null,
            productImagesComponentKey: 0,
            productsList1: [],
            showAddedToCartDiv: false,
            addedToCart: false,
            tabs: null,
            totalPrice: '',
            quantityComponent: 0,
            cartQuantity: null,
            cartTotalPrice: null,
            guestUserAddToCart: false,
            SelectedQuantity: 1,
            states: [],
            productAddedToCartIndication: false,
            productErrorIndication: false,
            selectQuantityPopupFlag: false,
            callpermissionflag: false,
            error_enable: false,
            auth_error_enable: false,
        };
    },
    watch: {
        screenWidth(newWidth, oldWidth) {

        },
    },
    mounted() {
        this.product_id = this.$route.params.product_id;
        this.onMountCallBackFunction();
        this.userPageViewTable();
    },
    beforeRouteUpdate(to, from, next) {
        if (to.params.product_id !== from.params.product_id) {
            Object.assign(this.$data, this.initialState())
            this.product_id = to.params.product_id;
            this.onMountCallBackFunction();
            next()
        } 
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        userPageViewTable() {
            if (this.token === null) {
                var userPageViewTableBody = {
                    pageName: 'productDetailsPage',
                    typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                    pageName: 'productDetailsPage',
                    typeOfUser: 'CUSTOMER',
                    token: this.token
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
        },
        updateQuantity(data){
            let max_val = data>10?10 :data
            this.states = [];
            for (let i = 1; i <= max_val; i++) {
                this.states.push(i);
            }
        },
        checkIfLoginPopupClosed(loginPopupFlag) {
            this.guestUserAddToCart = loginPopupFlag;
        },
        onMountCallBackFunction() {
            window.addEventListener('resize', this.handleResize);
            this.token = this.$cookies.get('customerToken');
            // Fetch Product Data.
            axios_auth_instance_epharmacy_customer.get('/pagewise/getParticularProductDetailsWithStockInfo/' + this.product_id).then((getProductDetailsResponse) => {
                // Check Login.
                this.isUserLoggedIn = getProductDetailsResponse.data.authenticationStatus == 401 ? false : true;
                this.productDetails = {...this.productDetails, ...getProductDetailsResponse.data.productInfo};
                this.productDetails.isPriceDataLoading = true;
                this.breadcrumbs[this.breadcrumbs.length - 1].text = this.productDetails.product_name;
                // this.availableStock = this.productDetails.stock_qty;
                //   this.productDetails.quantity_added = this.productDetails.cart_qty ? this.productDetails.cart_qty : 0;
                //   if (this.productDetails.quantity_added > 0) {
                //       this.addedToCart = true;
                //   }
                this.getPriceFromOdoo(this.productDetails.default_product_variant.id);
                // Product Images.
                this.productImages = [{ URL: this.productDetails.product_icon_image }, ...this.productDetails.product_image_url_list];
                if (this.productDetails.product_variant_count) {
                    this.selectedVariant = this.productDetails.default_product_variant.id;
                }
                if (this.productImages.length == 0) {
                    this.productImages = [{ URL: 'https://s3iconimages.mymedicine.com.mm/product_fallback_image_large.svg' }];
                }
                this.productImagesComponentKey++;
                // this.productStockFlag = (this.productDetails.avail_status == undefined || this.productDetails.avail_status == true) && (this.productDetails.sell_ok == undefined || this.productDetails.sell_ok == true) && (this.availableStock > 0);
                this.skeletonLoaderFlag = false;
                this.callpermissionflag = true;
                this.updateQuantity(this.availableStock);
                if (!window.xm)
                    document.title = this.productDetails.product_name ? this.productDetails.product_name : 'Product';
                else
                    document.title = ' ';
            }).catch((getProductDetailsError) => {
                console.log('Error fetching common home page data: ', getProductDetailsError);
                if (getProductDetailsError.response && getProductDetailsError.response.status == 401) {
                    console.log('Unauthorized, Login again.');
                    this.$cookies.remove('customerToken');
                    this.auth_error_enable = true;
                } else {
                    this.error_enable = true;
                }
            });
            this.addedToCart = this.productDetails.quantity_added > 0 ? true : false;
            axios_auth_instance_epharmacy_customer.get('/products/collections', {params: {
                minAmount: 0,
                maxAmount: 150000,
                collectionList: ['1']
            }}).then((res) => {
                this.productsList1 = {
                    "type": "LISTINGS-ONE-ROW",
                    "item_type": "COLLECTIONS",
                    "title": "Best Sellers",
                    "background": "white",
                    "items": [
                        {
                            "items": res.data.data || [],
                            "tab_link": "1",
                        }
                    ],
                    "redirection_label": "View More",
                    "redirection_label_burmese": "နောက်ထပ်",
                    "displayJumpLink": true,
                };
                this.bestSalersLoader = false;
            }).catch((error) => {
                this.bestSalersLoader = false;
                this.error_enable = true;
                //   handleError(error, this.$router, "getBestSalerCollection", "CommonHomePage", "customerToken");
            });
                },
        initialState(){
            return {
                variantLoading: false,
                bestSalersLoader: true,
                token: null,
                screenWidth: window.innerWidth,
                skeletonLoaderFlag: true,
                breadcrumbs: [
                    {
                        text: 'HOME',
                        disabled: false,
                        href: window.location.origin + '/'
                    },
                    {
                        text: 'PHARMACY',
                        disabled: false,
                        href: window.location.origin + '/customer/epharmacyHome'
                    },
                    {
                        text: 'CATEGORIES',
                        disabled: false,
                        href: window.location.origin + '/customer/allcategories'
                    },
                    {
                        text: 'PRODUCT',
                        disabled: true,
                        href: window.location.origin + '/customer/productdetails/' + this.$route.params.product_id
                    },
                ],
                commonHomeComponentKey: 0,
                isUserLoggedIn: false,
                productIndex: 0,
                product_id: null,
                headerTabs: null,
                productStockFlag: true,
                availableStock: null,
                quantityLimitReached: false,
                addToCartLoaderFlag: false,
                addToCartErrorFlag: false,
                addToCartErrorPopupFlag: false,
                removeFromCartFlag: false,
                selectedVariant: null,
                productDetails: {
                    images: [],
                    category: '',
                    name: '',
                    name_mm: '',
                    product_code: '',
                    packaging_size: '',
                    manufacturer: '',
                    product_variants: [],
                    variants: [],
                    price: '',
                    quantity_added: 0,
                    max_quantity_permitted: null,
                    selling_unit: '',
                    description_tabs: [
                        'Description',
                        'Uses',
                        'Side Effects',
                        'Prescription and Warnings'
                    ]
                },
                productImages: null,
                productImagesComponentKey: 0,
                productsList1: [],
                showAddedToCartDiv: false,
                showErroDiv: false,
                addedToCart: false,
                tabs: null,
                totalPrice: '',
                quantityComponent: 0,
                cartQuantity: null,
                cartTotalPrice: null,
                guestUserAddToCart: false,
                SelectedQuantity: 1,
                states: [],
                productAddedToCartIndication: false,
                productErrorIndication: false,
                selectQuantityPopupFlag: false,
                callpermissionflag: false,
                error_enable: false,
                auth_error_enable: false
            };
        },
        getNewVariant(value) {
            this.variantLoading = true;
            this.productStockFlag = null
            axios_auth_instance_epharmacy_customer.get('/pagewise/getParticularVariantDetailsWithStockInfo/' + this.product_id + '/' + this.selectedVariant).then((getProductDetailsResponse) => {
                // Check Login.
                this.isUserLoggedIn = getProductDetailsResponse.data.stockInfo ? true : false;
                this.productDetails['list_price'] = getProductDetailsResponse.data.productInfo.list_price;
                this.productDetails['product_image_url_list'] = getProductDetailsResponse.data.productInfo.product_image_url_list;
                this.productDetails['product_icon_image'] = getProductDetailsResponse.data.productInfo.product_icon_image;
                this.productDetails['stock_qty'] = getProductDetailsResponse.data.productInfo.stock_qty;              
                // this.availableStock = this.productDetails.stock_qty;
                // this.totalPrice = this.productDetails.list_price;
                this.getPriceFromOdoo(this.selectedVariant);
                // Product Images.
                this.productImages = [{ URL: this.productDetails.product_icon_image }, ...this.productDetails.product_image_url_list];
                if (this.productImages.length == 0) {
                    this.productImages = [{ URL: 'https://s3iconimages.mymedicine.com.mm/product_fallback_image_large.svg' }];
                }
                this.productImagesComponentKey++;
                this.variantLoading = false;
                this.updateQuantity(this.availableStock)
                this.callpermissionflag = true;
            }).catch((getProductDetailsError) => {
                console.log('Error fetching common home page data: ', getProductDetailsError);
                if (getProductDetailsError.response && getProductDetailsError.response.status == 401) {
                    console.log('Unauthorized, Login again.');
                    this.$cookies.remove('customerToken');
                    this.auth_error_enable = true;
                } else {
                    this.error_enable = true;
                }
            });
            // alert(this.selectedVariant)
        },
        redirectBack() {
            this.$router.go(-1);
        },
        redirectToCartPage() {
            if(this.token) {
                this.$router.push({
                    path: '/customer/cart'
                });
            } else {
                this.$router.push({
                    name: 'Login'
                });
            }
        },
        getCartDataFromCommonHomeHeader(data) {
            this.cartQuantity = data.cart_count;
            this.cartTotalPrice = data.cart_value;
        },
        handleResize() {
            this.screenWidth = window.innerWidth;
            if (this.screenWidth <= 600) {

            } else {

            }
        },
        decrementQuantity() {
            if (this.productDetails.quantity_added <= 1) {
                // Call Remove from Cart API.
                this.removeFromCart();
            } else if (this.productDetails.quantity_added > 0) {
                this.addToCartLoaderFlag = true;
                this.addToCart(this.productDetails.quantity_added - 1);
            }
        },
        incrementQuantity() {
            if (this.token===null) {
                if(this.screenWidth > 600) {
                    // WEB
                    this.guestUserAddToCart = true;
                } else {
                    // MOBILE
                    this.$router.push({
                        name: 'Login'
                    });
                }
            } else {
                this.addToCartLoaderFlag = true;
                if (this.SelectedQuantity >= 0 && this.SelectedQuantity <= this.availableStock) {
                    // Call Add to Cart API.
                    this.addToCart(this.SelectedQuantity);
                } else if (this.SelectedQuantity > this.availableStock) {
                    // Throw Popup saying cant add anymore due to stock inavailability.
                    this.quantityLimitReached = true;
                }
            }
        },
        addToCart(productQuantity) {
            // Call Add To Cart API.
            let addToCartRequest = {
                productId: this.selectedVariant?this.selectedVariant:this.productDetails.product_id,
                productQty: productQuantity,
            }
            this.productAddedToCartIndication = true;
            this.productDetails.quantity_added = productQuantity;
            this.totalPrice = this.productDetails.list_price * (productQuantity);
            this.quantityComponent++;
            this.addedToCart = true;
            this.addToCartLoaderFlag = true;
            this.cartQuantity+=1;
            if (this.selectQuantityPopupFlag) {
                this.selectQuantityPopupFlag = false;
            }
            //rendering the common-home-header to update the cart values
            this.commonHomeComponentKey++;
            this.showAddedToCartDiv = true;
            setTimeout(() => {
                this.productAddedToCartIndication = false;
            }, 3000);
            this.addToCartLoaderFlag = false;
            axios_auth_instance_epharmacy_customer.post('/cart', addToCartRequest).then((addToCartResponse) => {
                this.callpermissionflag = true;
            }).catch((addToCartError) => {
                console.log('Error adding to cart: ', addToCartError);
                this.productAddedToCartIndication = false;
                this.productErrorIndication = true;
                productQuantity = 1;
                this.productDetails.quantity_added = productQuantity;
                this.totalPrice = this.productDetails.list_price * (productQuantity);

                this.quantityComponent--;
                this.addedToCart = false;
                this.addToCartLoaderFlag = false;
                this.cartQuantity-=1;
                if (this.selectQuantityPopupFlag) {
                    this.selectQuantityPopupFlag = false;
                }

                //rendering the common-home-header to update the cart values
                this.commonHomeComponentKey--;
                this.showAddedToCartDiv = false;
              
                setTimeout(() => {
                    this.productErrorIndication = false;
                }, 3000);
                // Throw popup saying something went wrong while updating cart.
                this.addToCartErrorFlag = true;
                if (addToCartError.response && addToCartError.response.status == 401) {
                    this.$cookies.remove('customerToken');
                    this.auth_error_enable = true;
                } else {
                    this.error_enable = true;
                }
                if(this.screenWidth > 600){
                    this.addToCartErrorPopupFlag = true;
                }
            });
        },
        removeFromCart() {
            // Remove From Cart API.
            this.addToCartLoaderFlag = true;
            axios_auth_instance_epharmacy_customer.delete('/cart/'+ this.productDetails.product_id).then((removeFromCartResponse) => {
                this.productDetails.quantity_added = 0;
                this.totalPrice = this.productDetails.list_price;
                this.commonHomeComponentKey++;
                this.addedToCart = false;
                this.showAddedToCartDiv = false;
                this.addToCartLoaderFlag = false;
                this.callpermissionflag = true;
            }).catch((removeFromCartError) => {
                console.log('Error adding to cart: ', removeFromCartError);
                // Throw popup saying something went wrong while updating cart.
                this.removeFromCartFlag = true;
                this.addToCartLoaderFlag = false;
                this.addToCartErrorPopupFlag=false;
                if (removeFromCartError.response && removeFromCartError.response.status == 401) {
                    this.$cookies.remove('customerToken');
                    this.auth_error_enable = true;
                } else {
                    this.error_enable = true;
                }
            });
        },
        addToCartErrorPopupClose() {
            this.addToCartErrorFlag = false;
            this.addToCartLoaderFlag = false;
        },
        addToCartFromBottomSticky() {
            if (this.selectQuantityPopupFlag) {
                this.incrementQuantity();
            } else {
                this.selectQuantityPopupFlag = true;
            }
        },
        async getPriceFromOdoo(reqProductId) {
            this.productDetails.isPriceDataLoading = true;
            this.addToCartLoaderFlag = true;
            await axios_auth_instance_epharmacy_customer.get('/products/' + reqProductId)
            .then((productPriceResponse) => {
                let responseDetails = productPriceResponse.data.productInfo.data
                let discountedProduct = this.productDetails.product_variants.find(item => item.id === reqProductId);
                if (discountedProduct.has_discounted_price) {
                    this.totalPrice = discountedProduct.price;
                    this.productDetails.list_price = discountedProduct.list_price;
                    this.productDetails.discountedPrice = discountedProduct.price
                    this.productDetails.hasDiscount = discountedProduct.has_discounted_price;
                } else {
                    this.totalPrice = responseDetails.lst_price;
                    this.productDetails.list_price = responseDetails.lst_price;
                }
                this.productDetails.isPriceDataLoading = false;
                this.addToCartLoaderFlag = false
                if (responseDetails.is_available) {
                    this.availableStock = responseDetails.quantity_available
                    if(this.availableStock > 0) {
                        this.productStockFlag = true;
                        this.updateQuantity(this.availableStock)
                    } else {
                        this.productStockFlag = false;
                        this.updateQuantity(false)
                    }
                } else {
                    this.productStockFlag = false;
                    this.availableStock = false
                    this.updateQuantity(this.availableStock)
                }
            })
            .catch((productPriceError)=>{
                console.log('productPriceError', productPriceError);
                this.addToCartErrorFlag = true;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/global.scss';
@import '../../../scss/classes.scss';

.loader-web, .navigation-tabs-segment, .web-header-segment, .breadcrumbs-segment, .display-web {
    @media (max-width: 600px) {
        display: none !important;
	}

  @media (min-width: 601px) and (max-width: 1264px) {
      
  }

  @media (min-width: 1265px) {
      
  }
}

.loader-mobile, .mobile-header-segment, .display-mobile {
    @media (max-width: 600px) {
        
	}

  @media (min-width: 601px) and (max-width: 1264px) {
      display: none;
  }

  @media (min-width: 1265px) {
      display: none;
  }
}
.cart-icon {
  position: relative;
}

.products-length-container {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.products-length {
  width: 18px;
  height: 18px;
  border-radius: 50%;
}
.segment-2, .segment-3, .segment-4, .segment-5, .segment-6 {
  width: 100%;
  @media (max-width: 600px) {
      border-radius: 0px !important;
  }

  @media (min-width: 601px) and (max-width: 1264px) {
      border-radius: 0px !important;
  }

  @media (min-width: 1265px) {
      border-radius: 8px !important;
  }
}
.button-class {
  letter-spacing: 0px;
}

.action-button {
  @media (max-width: 600px) {
      width: 50%;
      height: 40px;
  }

  @media (min-width: 601px) and (max-width: 1264px) {
      width: 35%;
      height: 40px;
  }

  @media (min-width: 1265px) {
      width: 25%;
      height: 40px;
  }
}
.text-field {
  font-size: 12px;
}

.product-info {
  width: 100%;
  display: flex;

  @media (max-width: 800px) {
      flex-direction: column;
  }

  @media (min-width: 801px) and (max-width: 1264px) {
      flex-direction: row;
      margin-top: 16px;
      margin-bottom: 16px;
  }

  @media (min-width: 1265px) {
      flex-direction: row;
      margin-top: 32px;
      margin-bottom: 32px;
  }
}

.images-container {
  @media (max-width: 800px) {
      width: 100%;
  }

  @media (min-width: 801px) and (max-width: 1264px) {
      padding-top: 24px;
      width: 40%;
  }

  @media (min-width: 1265px) {
      padding-top: 24px;
      width: 40%;
  }
}

.data-container {
  @media (max-width: 800px) {
      width: 100%;
      padding: 16px;
  }

  @media (min-width: 801px) and (max-width: 1264px) {
      width: 60%;
      padding: 8px;
  }

  @media (min-width: 1265px) {
      width: 60%;
      padding: 16px 8px;
  }
}

.description-tags {
  @media (max-width: 800px) {
  }

  @media (min-width: 801px) and (max-width: 1264px) {
      padding: 8px 0px;
  }

  @media (min-width: 1265px) {
      padding: 8px 0px;
  }
}

.description-tag-info {
  height: 30vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.description-ratings {
  @media (max-width: 800px) {
  }

  @media (min-width: 801px) and (max-width: 1264px) {
  }

  @media (min-width: 1265px) {
  }
}

.delivery-segment {
  @media (max-width: 600px) {
  }

  @media (min-width: 601px) and (max-width: 1264px) {
      width: 70%;
  }

  @media (min-width: 1265px) {
      width: 60%;
  }
}

.product-container {
  @media (max-width: 800px) {
      background-color: white;
  }

  @media (min-width: 801px) and (max-width: 1264px) {
      background-color: $light-grey-color;
      padding-top: 16px;
  }

  @media (min-width: 1265px) {
      background-color: $light-grey-color;
      padding-top: 32px;
  }
}

.products-component-1 {
  // my-sm-4 my-lg-8
  @media (max-width: 800px) {
  }

  @media (min-width: 801px) and (max-width: 1264px) {
      margin: 16px 0px;
  }

  @media (min-width: 1265px) {
      margin: 32px 0px;
  }
}

.products-component-2 {
  //  pb-4 pb-lg-8
  @media (max-width: 800px) {
      padding-bottom: 16px;
  }

  @media (min-width: 801px) and (max-width: 1264px) {
      margin: 16px 0px;
      padding-bottom: 16px;
  }

  @media (min-width: 1265px) {
      margin: 32px 0px;
      padding-bottom: 32px;
  }
}

.product-display-image-web {
  object-fit: fill;
  aspect-ratio: 1;
}

.product-images-carousel {
  background-color: $light-grey-color;
}

.product-display-image-mobile {
  height: 30vh;
  object-fit: fill;
  object-position: center;
}

.product-quantity-width {
  width: 30px;
}
.bottom-navbar-segment {
    position: fixed;
}
.mobile-bottom-nav-bar {
  @media (max-width: 600px) {
  }

  @media (min-width: 601px) and (max-width: 1264px) {
      display: none !important;
  }

  @media (min-width: 1265px) {
      display: none !important;
  }
}
.view-cart-bar {
  @media (max-width: 600px) {
  }

  @media (min-width: 601px) and (max-width: 1264px) {
  }

  @media (min-width: 1265px) {
      border-radius: 8px;
  }
}
.selector-container  {
  display: flex;
  padding: px 16px;
  justify-content: center;
  align-items: center;
  gap: 50px !important;
  border-radius: 4px;
  height: 40px;
  width:191px;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px !important;
  margin-top:20px !important;
}
.selector-row {
  width: 100%;
}
.red-background {
    background-color: red;
    color: white;
}
.custom-select.v-input__slot{
  height:20px;
  width:100%px;
  margin-right: 8px;
}
.cart-container{
display: flex;
width: 195px;
height: 36px;
padding: 8px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 4px;
background: #48ACEF;
color:white;

}

.bottom-sticky-shadow {
    box-shadow: 0px -4px 24px rgba(0, 36, 74, 0.1);
}

.select-quantity-popup {
    height: 392px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}

.add-to-cart-segment {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}

.close-button {
    min-width: 32px !important;
}
</style>

<style lang="scss">
@import '../../../scss/global.scss';
@import '../../../scss/classes.scss';

.display-mobile .v-divider {
  margin-bottom: 1px !important;
}

.v-text-field.v-text-field--solo .v-input__control {
  min-height: 10px;
}

.description-tags .v-tab, .navigation-tabs-segment .v-tab {
  text-transform: none;
  letter-spacing: 0px;
}

.description-tags .v-tabs:not(.v-tabs--vertical).v-tabs--right>.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__next, .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)>.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
  display: none;
}
</style>